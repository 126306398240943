module services {
    export module tariff {
        export class transportAgentService implements interfaces.tariff.ITransportAgentService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            //Gets list of all Transport Agents
            getDropdownList(countryId: number, transportModeId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "TransportAgent/GetForDropdown", {
                    countryId: countryId,
                    transportModeId: transportModeId,
                });
            }
        }
    }
    angular.module("app").service("transportAgentService", services.tariff.transportAgentService);
}